import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OtsService } from 'src/app/services/ots.service';
import { ParamService } from 'src/app/services/param.service';
import { SafePipe } from 'src/app/shared/lib/pipe.util';
@Component({
  selector: 'app-ots-consent',
  templateUrl: './ots-consent.component.html',
  styleUrls: ['./ots-consent.component.css'],
})
export class OtsConsentComponent implements OnInit {
	public id: string = '';

	public loading: boolean = true;

	public error: boolean = false;
	public welcome: boolean = false;

	id_ots: any;
	public consentOK: boolean =false;
	public popupMessage: string = '';
	otsConsentMessageYes: any;
	otsConsentMessageNo: any;
	otsConsentMessageInfo: any;
	showConfirmSOR: boolean = false;
	

	constructor(
		public activeRoute: ActivatedRoute,
		public otsService: OtsService,
		private paramService: ParamService,
		
	) {    this.initializeElements()}

	private async initializeElements(): Promise<void> {
		this.otsConsentMessageYes = await this.paramService.getParam('OTS', 'WEB_OTS_CONSENT_YES').toPromise();
		this.otsConsentMessageNo = await this.paramService.getParam('OTS', 'WEB_OTS_CONSENT_NO').toPromise();
		this.otsConsentMessageInfo = await this.paramService.getParam('OTS', 'WEB_OTS_CONSENT_INFO').toPromise();
		await this.getConsent()
	}

	async getConsent() {
	try {
		this.loading = true;
		const info = await this.otsService.getOtsConsent(this.id_ots).toPromise();

		if (info.sor_consent) {
			this.consentOK = true;
		}

		if (!info.sor_consent) {
			this.welcome = true;
		} else if (this.consentOK) {
			this.popupMessage = this.otsConsentMessageYes.value;
		} else {
			this.popupMessage = this.otsConsentMessageNo.value;
		}
		
	} catch (error) {
		console.error('Error fetching consent:', error);
	} finally {
		this.loading = false;
	}
	}



	ngOnInit(): void {
		this.id_ots = this.activeRoute.snapshot.params['id'];
		
	
	}

	public async otsAction(action: boolean): Promise<void> {
		this.loading = true;
		this.welcome = false;

		const body = {
			id_ots: this.id_ots,
			sor_consent: action,
		};
		try {
			const res =await this.otsService.otsConsentAction(body).toPromise();
			
			if(res){
				this.consentOK = true;
			}
			
			if (this.consentOK === true)  this.popupMessage = this.otsConsentMessageYes.value
		
			else  this.popupMessage = this.otsConsentMessageNo.value
			
				
		} catch (err) {
			this.error = true;
			throw err;
		}
	}
}
