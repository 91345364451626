import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class OtsService extends BaseService {

    constructor(public http: HttpClient) {
        super('/api/customer/ots/', http);
    }
     
    public getOts(id_ots: string): Observable<any> { 
        return this.doGet(this.REST_URL + '' + id_ots);
    }
    
    public getOtsConsent(id_ots: string): Observable<any> {
        return this.doGet(this.REST_URL + 'consent/' + id_ots);
    }

    public otsConsentAction(body:any): Observable<any> { 
        return this.doPost(this.REST_URL + '',  body);
    }

}