import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OtsService } from 'src/app/services/ots.service';
import { ParamService } from 'src/app/services/param.service';

@Component({
    selector: 'app-ots-sor',
    templateUrl: './ots-sor.component.html',
    styleUrls: ['./ots-sor.component.css']
})
export class OtsSorComponent implements OnInit {
    public id: string = '';

    public loading: boolean = true;

    public error: boolean = false;
    public welcome: boolean = false;

    id_ots: any;
    public consentOK: boolean = false;
    public popupMessage: string = '';
    otsConsentMessageYes: any;
    otsConsentMessageNo: any;
    otsConsentMessageInfo: any;
    public sorOptions: any
    public selectedSOR: any;
    public showConsent: boolean = false;
    showWelcome = true;

    constructor(
        public activeRoute: ActivatedRoute,
        public otsService: OtsService,
        private paramService: ParamService,

    ) { this.initializeElements() }

    private async initializeElements(): Promise<void> {
        this.otsConsentMessageYes = await this.paramService.getParam('OTS', 'WEB_OTS_CONSENT_YES').toPromise();
        this.otsConsentMessageNo = await this.paramService.getParam('OTS', 'WEB_OTS_CONSENT_NO').toPromise();
        this.otsConsentMessageInfo = await this.paramService.getParam('OTS', 'WEB_OTS_CONSENT_INFO').toPromise();
        await this.getConsent()
    }

    async getConsent() {
        try {
            this.id_ots = this.activeRoute.snapshot.params['id'];
            const info = await this.otsService.getOtsConsent(this.id_ots).toPromise();
            const ots = await this.otsService.getOts(this.id_ots).toPromise();
            this.sorOptions = ots.sor

            if (info.sor_consent && info.switchorderreference) {
                this.consentOK = true;
            }

            if (info.sor_consent === null) {
                this.welcome = true;
            } else if (this.consentOK) {
                this.popupMessage = this.otsConsentMessageYes.value;
            } else {
                this.popupMessage = this.otsConsentMessageNo.value;
            }

        } catch (error) {
            console.error('Error fetching consent:', error);
        } finally {
            this.loading = false;
        }
    }

    ngOnInit(): void { }

    public async otsAction(action: boolean): Promise<void> {

        if (!this.selectedSOR) {
            return alert('Please select at least one SOR.');
        }

        this.loading = true;
        this.welcome = false;

        const body = {
            id_ots: this.id_ots,
            sor_consent: action,
            sor: this.selectedSOR
        };
        try {

            const res = await this.otsService.otsConsentAction(body).toPromise();

            if (res.sor_consent) {
                this.consentOK = true;
            }

            if (this.consentOK === true) this.popupMessage = this.otsConsentMessageYes.value

            else this.popupMessage = this.otsConsentMessageNo.value


        } catch (err) {
            this.error = true;
            throw err;
        }
    }

    onRowClick(sor: any): void {
        this.sorOptions.forEach((s: { selected: boolean; }) => s.selected = false);
        sor.selected = true;

    }

    public getSwichTypeLabel(type: string): string {
        let label = '';

        if (type.toUpperCase() == 'IAS') {
            label = 'Broadband';
        } else if (type.toUpperCase() == 'NBICS') {
            label = 'Voice';
        }
        return label;
    }

    public getOptionTypeLabel(type: string): string {
        let label = '';

        switch (type.toUpperCase()) {

            case 'SERVICEFOUND':
                label = 'Your product is included in your switch to us.';
                break;
            case 'SERVICENOTFOUND':
                label = 'If you continue the switch, this product is not included.';
                break;
            case 'FORCEDCEASE':
                label = 'This product must be ceased to continue with the switch.';
                break;
            case 'OPTIONTORETAIN':
                label = 'You keep your product with your current provider.';
                break;
            case 'OPTIONTOCEASE':
                label = 'Gives you the option to remove this product from your current provider.';
                break;
            default:
                label = 'Unknown option type';
        }

        return label;
    }

    public onConfirmSOR(sor: string) {
        this.selectedSOR = sor;
        this.showConsent = true;
        this.showWelcome = false;
    }

    public onProgress(action: string): void {
        if (action === 'PAGE1') {
            this.showWelcome = true;
            this.showConsent = false;
        } else if (action === 'PAGE2') {
            this.showWelcome = false
            this.showConsent = true
        }
    }

}
